.Settings {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.SettingsNav {
  display: flex;
  flex-direction: column;
}

.SettingsNavLink {
  padding-left: 7px;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
  height: 40px;
  transition: all 0.3s;
}

.SettingsNavLink:hover,
.SettingsNavLink:focus {
  border-left: 3px solid var(--color-primary);
}

.SettingsNavLinkActive {
  border-left: 3px solid var(--color-primary);
}

@media (min-width: 480px) {
  .Settings {
    grid-template-columns: 1fr 2fr;
  }
}

@media (min-width: 500px) {
  .Settings {
    grid-template-columns: 1fr 3fr;
  }
}