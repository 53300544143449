.Container {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

/* .Container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 20px;
} */

/* 320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV. */

@media (min-width: 769px) {
  .Container {
    /* padding: 25px 40px; */
    width: 90%;
  }
}

@media (min-width: 1201px) {
  .Container {
    padding: 50px 250px;
  }
}