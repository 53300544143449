.TableContainer {
  width: 100%;
}

.Table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: var(--color-primary);
  table-layout: fixed;
}

.Table th,
.Table td {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Head */
.Table th {
  --header-radius: 4px;
  background-color: var(--color-primary);
  color: var(--color-background);
}

.Table th:first-child {
  border-top-left-radius: var(--header-radius);
  border-bottom-left-radius: var(--header-radius);
}

.Table th:last-child {
  border-top-right-radius: var(--header-radius);
  border-bottom-right-radius: var(--header-radius);
}

/* Body */
.Table td {
  transition: all 0.2s;
}