.WeatherWidget {
  display: flex;
  visibility: hidden;
}

.WeatherDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  color: var(--color-primary);
  margin-left: 10px;
  font-weight: 500;
}

.WeatherDetails span:first-child {
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 5px;
}

.WeatherDetails span:last-child {
  padding-top: 5px;
}

@media (min-width: 600px) {
  .WeatherWidget {
    visibility: visible;
  }
}